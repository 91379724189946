<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="group_1 flex-row">
        <span class="text_1">DIGITAL&nbsp;MONEYBOX</span>
        <span class="text_2" @click="jumpage('/home')">HOME</span>
        <span class="text_3" @click="jumpage('/about')">About</span>
        <div class="text-wrapper_1 flex-col"><span class="text_4" @click="jumpage('/services')">Services</span></div>
        <span class="text_5" @click="jumpage('/contact')">Contact</span>
      </div>
      <span class="text_6">Our&nbsp;Services</span>
    </div>
    <div class="box_2 flex-col">
      <div class="text-wrapper_2 flex-col"><span class="text_7">WHY&nbsp;CHOOSE&nbsp;US</span></div>
      <span class="text_8">Digital&nbsp;Solutions&nbsp;Service&nbsp;We&nbsp;Provide</span>
      <div class="list_1 flex-row">
        <div
          class="list-items_1 flex-col"
          :style="{ background: item.lanhuBg0 }"
          v-for="(item, index) in loopData0"
          :key="index"
        ></div>
      </div>
      <div class="box_3 flex-col">
        <div class="text-wrapper_3 flex-col"><span class="text_9">WHY&nbsp;CHOOSE&nbsp;US</span></div>
        <span class="text_10">Digital&nbsp;Solutions&nbsp;Service&nbsp;We&nbsp;Provide</span>
        <span class="text_11">
          Brindarle&nbsp;asistencia&nbsp;financiera&nbsp;en&nbsp;cualquier&nbsp;momento&nbsp;y&nbsp;en&nbsp;cualquier&nbsp;lugar.Brindarle&nbsp;asistencia&nbsp;financiera&nbsp;en&nbsp;cualquier&nbsp;momento&nbsp;y&nbsp;en&nbsp;cualquier&nbsp;lugar.Brindarle&nbsp;asistencia&nbsp;financiera&nbsp;en&nbsp;cualquier&nbsp;momento&nbsp;y&nbsp;en&nbsp;cualquier&nbsp;lugar.
        </span>
      </div>
      <div class="box_4 flex-row justify-between">
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng0b2979a72f34bfa5b0e25f47fb3032198c560aba5ed31a5847e8b6beb88b7d22.png"
        />
        <div class="box_5 flex-col justify-between">
          <div class="text-wrapper_4 flex-col"><span class="text_12">WHY&nbsp;CHOOSE&nbsp;US</span></div>
          <div class="text-group_1 flex-col justify-between">
            <span class="text_13">Proceso&nbsp;sencillo</span>
            <span class="text_14">
              Brindarle&nbsp;asistencia&nbsp;financiera&nbsp;en&nbsp;cualquier&nbsp;momento&nbsp;y&nbsp;en&nbsp;cualquier&nbsp;lugar.Brindarle&nbsp;asistencia&nbsp;financiera&nbsp;en&nbsp;cualquier&nbsp;momento&nbsp;y&nbsp;en&nbsp;cualquier&nbsp;lugar.Brindarle&nbsp;asistencia&nbsp;financiera&nbsp;en&nbsp;cualquier&nbsp;momento&nbsp;y&nbsp;en&nbsp;cualquier&nbsp;lugar.
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="box_6 flex-col">
      <div class="section_1 flex-row">
        <div class="text-group_2 flex-col justify-between">
          <span class="text_15">DIGITAL&nbsp;MONEYBOX</span>
          <span class="text_16">
            Brindarle&nbsp;asistencia&nbsp;financiera&nbsp;en&nbsp;cualquier&nbsp;momento&nbsp;y&nbsp;en&nbsp;cualquier&nbsp;lugar.Brindarle&nbsp;asistencia&nbsp;financiera&nbsp;en&nbsp;cualquier&nbsp;momento&nbsp;y&nbsp;en&nbsp;cualquier&nbsp;lugar.
          </span>
        </div>
        <div class="text-wrapper_5 flex-col justify-between">
          <span class="text_17">Compañía&nbsp;colaboradora</span>
          <span class="text_18">
            Créditos&nbsp;Formales&nbsp;de&nbsp;México,&nbsp;S.A.P.I.&nbsp;de&nbsp;C.V.,&nbsp;SOFOM,&nbsp;E.N.R.
          </span>
        </div>
        <div class="group_2 flex-col">
          <span class="text_19">Contáctenos</span>
          <div class="image-text_1 flex-row justify-between">
            <img
              class="image_2"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng7fc7ab6f1496c985f3ae729a46450eba96e8c0717bc3520f78429f90a37cff1e.png"
            />
            <span class="text-group_3">support&#64;mercurioestrategica.com</span>
          </div>
          <div class="image-text_2 flex-row justify-between">
            <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng9b220cb481e58d71c7a345883e024f9a55997d950a59bd3c8b53d80ff98a2044.png"
            />
            <span class="text-group_4">
              Fontaneros&nbsp;#&nbsp;83&nbsp;Mz&nbsp;4&nbsp;Lt&nbsp;59&nbsp;U&nbsp;Habitacional&nbsp;El&nbsp;Rosario&nbsp;Tlalnepantla&nbsp;de&nbsp;Baz&nbsp;Mexico&nbsp;54090
            </span>
          </div>
        </div>
      </div>
      <div class="section_2 flex-col"></div>
      <span class="text_20">&nbsp;©&nbsp;Copyright&nbsp;by&nbsp;DIGITAL&nbsp;MONEYBOX</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/3ac2fdf553b54142ad449738f97f347d_mergeImage.png)'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/85675564fab54019b1c1baf5189aa420_mergeImage.png)'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/5943bc4227b048f9840f20eabb23a930_mergeImage.png)'
        }
      ],
      constants: {}
    };
  },
  methods: {
    jumpage(url){
      this.$router.push(url)
    }
  }
};
</script>
<style scoped lang="less" src="./assets/index.less" />