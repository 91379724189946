<template>
  <div class="page flex-col">
    <div class="section_1 flex-col">
      <div class="box_1 flex-row">
        <span class="text_1">DIGITAL&nbsp;MONEYBOX</span>
        <span class="text_2" @click="jumpage('/home')">HOME</span>
        <div class="text-wrapper_1 flex-col"><span class="text_3" @click="jumpage('/about')">About</span></div>
        <span class="text_4" @click="jumpage('/services')">Services</span>
        <span class="text_5" @click="jumpage('/contact')">Contact</span>
      </div>
      <span class="text_6">About&nbsp;Us</span>
    </div>
    <div class="section_2 flex-col">
      <div class="text-wrapper_2 flex-col"><span class="text_7">WHY&nbsp;CHOOSE&nbsp;US</span></div>
      <span class="text_8">Digital&nbsp;Solutions&nbsp;Service&nbsp;We&nbsp;Provide</span>
      <div class="list_1 flex-row">
        <div
          class="list-items_1 flex-col"
          :style="{ background: item.lanhuBg0 }"
          v-for="(item, index) in loopData0"
          :key="index"
        ></div>
      </div>
      <div class="group_1 flex-row justify-between">
        <div class="group_2 flex-col justify-between">
          <div class="text-wrapper_3 flex-col"><span class="text_9">WHY&nbsp;CHOOSE&nbsp;US</span></div>
          <div class="text-group_1 flex-col justify-between">
            <span class="text_10">Proceso&nbsp;sencillo</span>
            <span class="text_11">
              Brindarle&nbsp;asistencia&nbsp;financiera&nbsp;en&nbsp;cualquier&nbsp;momento&nbsp;y&nbsp;en&nbsp;cualquier&nbsp;lugar.Brindarle&nbsp;asistencia&nbsp;financiera&nbsp;en&nbsp;cualquier&nbsp;momento&nbsp;y&nbsp;en&nbsp;cualquier&nbsp;lugar.Brindarle&nbsp;asistencia&nbsp;financiera&nbsp;en&nbsp;cualquier&nbsp;momento&nbsp;y&nbsp;en&nbsp;cualquier&nbsp;lugar.
            </span>
          </div>
        </div>
        <div class="group_3 flex-col justify-between">
          <div class="section_3 flex-row">
            <span class="text_12">Strong&nbsp;Basement</span>
            <span class="text_13">1234</span>
            <span class="text_14">Strong&nbsp;Basement</span>
            <span class="text_15">659</span>
            <div class="image-wrapper_1 flex-col">
              <img
                class="image_1"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngbcac34faa3c992c43834a6787f0daadd0f831c69b52a09da2dc1bec97a389694.png"
              />
            </div>
            <div class="image-wrapper_2 flex-col">
              <img
                class="image_2"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngdec78be35ca46d7de6674d2c93d2d1c1f50d1c74fa98019a16bd0a340d3b383f.png"
              />
            </div>
          </div>
          <div class="section_4 flex-row">
            <span class="text_16">Strong&nbsp;Basement</span>
            <span class="text_17">12</span>
            <span class="text_18">Strong&nbsp;Basement</span>
            <span class="text_19">48251</span>
            <span class="text_20">Strong&nbsp;Basement</span>
            <span class="text_21">7895</span>
            <div class="image-wrapper_3 flex-col">
              <img
                class="image_3"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng9d420d222b9ec0074623ea8a959e965f00d9d0b744f9b5da05be1107c83be191.png"
              />
            </div>
            <div class="image-wrapper_4 flex-col">
              <img
                class="image_4"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng6159a5e05a8e8ef3e16c05ec0d59381f863264d0acc9b830513ced6762631368.png"
              />
            </div>
            <div class="image-wrapper_5 flex-col">
              <img
                class="image_5"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng265911bfe852e2e46e002c95c7461d77ae084744aefda034eac9c63e532451dd.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="group_4 flex-row justify-between">
        <div class="group_5 flex-col justify-between">
          <div class="text-wrapper_4 flex-col"><span class="text_22">WHY&nbsp;CHOOSE&nbsp;US</span></div>
          <div class="text-group_2 flex-col justify-between">
            <span class="text_23">Proceso&nbsp;sencillo</span>
            <span class="text_24">
              Brindarle&nbsp;asistencia&nbsp;financiera&nbsp;en&nbsp;cualquier&nbsp;momento&nbsp;y&nbsp;en&nbsp;cualquier&nbsp;lugar.Brindarle&nbsp;asistencia&nbsp;financiera&nbsp;en&nbsp;cualquier&nbsp;momento&nbsp;y&nbsp;en&nbsp;cualquier&nbsp;lugar.Brindarle&nbsp;asistencia&nbsp;financiera&nbsp;en&nbsp;cualquier&nbsp;momento&nbsp;y&nbsp;en&nbsp;cualquier&nbsp;lugar.
            </span>
          </div>
        </div>
        <img
          class="image_6"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng6b440ef14ed433b9cb4261fe4d9f6de53dfb24ea70bca063a320727c0744a831.png"
        />
      </div>
    </div>
    <div class="section_5 flex-col">
      <div class="box_2 flex-row">
        <div class="text-group_3 flex-col justify-between">
          <span class="text_25">DIGITAL&nbsp;MONEYBOX</span>
          <span class="text_26">
            Brindarle&nbsp;asistencia&nbsp;financiera&nbsp;en&nbsp;cualquier&nbsp;momento&nbsp;y&nbsp;en&nbsp;cualquier&nbsp;lugar.Brindarle&nbsp;asistencia&nbsp;financiera&nbsp;en&nbsp;cualquier&nbsp;momento&nbsp;y&nbsp;en&nbsp;cualquier&nbsp;lugar.
          </span>
        </div>
        <div class="text-wrapper_5 flex-col justify-between">
          <span class="text_27">Compañía&nbsp;colaboradora</span>
          <span class="text_28">
            Créditos&nbsp;Formales&nbsp;de&nbsp;México,&nbsp;S.A.P.I.&nbsp;de&nbsp;C.V.,&nbsp;SOFOM,&nbsp;E.N.R.
          </span>
        </div>
        <div class="group_6 flex-col">
          <span class="text_29">Contáctenos</span>
          <div class="image-text_1 flex-row justify-between">
            <img
              class="image_7"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng68439ee78536f2324175e9306a71d5a5e241e01019cab377fa670e3722af7c11.png"
            />
            <span class="text-group_4">support&#64;mercurioestrategica.com</span>
          </div>
          <div class="image-text_2 flex-row justify-between">
            <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngfe0e39caf99fdade489c09b4ddc7ce8a176287a9fcbd3fced3da417bfd589e34.png"
            />
            <span class="text-group_5">
              Fontaneros&nbsp;#&nbsp;83&nbsp;Mz&nbsp;4&nbsp;Lt&nbsp;59&nbsp;U&nbsp;Habitacional&nbsp;El&nbsp;Rosario&nbsp;Tlalnepantla&nbsp;de&nbsp;Baz&nbsp;Mexico&nbsp;54090
            </span>
          </div>
        </div>
      </div>
      <div class="box_3 flex-col"></div>
      <span class="text_30">&nbsp;©&nbsp;Copyright&nbsp;by&nbsp;DIGITAL&nbsp;MONEYBOX</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/d4b1792cb1a349a5a8ce6e1327a887a3_mergeImage.png)'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/7e716cac2a074ea4951da81393b0637e_mergeImage.png)'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/168cda6fc33b419481f36d1fa07bb125_mergeImage.png)'
        }
      ],
      constants: {}
    };
  },
  methods: {
    jumpage(url){
      this.$router.push(url)
    }
  }
};
</script>
<style scoped lang="less" src="./assets/index.less" />