<template>
  <div class="page flex-col">
    <div class="block_1 flex-col">
      <div class="group_1 flex-row">
        <span class="text_1">DIGITAL&nbsp;MONEYBOX</span>
        <span class="text_2" @click="jumpage('/home')">HOME</span>
        <span class="text_3" @click="jumpage('/about')">About</span>
        <span class="text_5" @click="jumpage('/services')">services</span>

        <div class="text-wrapper_1 flex-col"><span class="text_4" @click="jumpage('/contact')">Contact</span></div>
      </div>
      <span class="text_6">Contact&nbsp;Us</span>
    </div>
    <div class="block_2 flex-col">
      <div class="list_1 flex-row">
        <div class="list-items_1 flex-col" v-for="(item, index) in loopData0" :key="index">
          <img class="image_1" referrerpolicy="no-referrer" :src="item.lanhuimage0" />
          <div class="text-group_1 flex-col">
            <span class="text_7" v-html="item.lanhutext0"></span>
            <span class="text_8" v-html="item.lanhutext1"></span>
            <span v-if="item.slot1 === 1" class="text_9" v-html="item.specialSlot1.lanhutext0"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="block_3 flex-col">
      <div class="group_2 flex-row">
        <div class="text-group_2 flex-col justify-between">
          <span class="text_10">DIGITAL&nbsp;MONEYBOX</span>
          <span class="text_11">
            Brindarle&nbsp;asistencia&nbsp;financiera&nbsp;en&nbsp;cualquier&nbsp;momento&nbsp;y&nbsp;en&nbsp;cualquier&nbsp;lugar.Brindarle&nbsp;asistencia&nbsp;financiera&nbsp;en&nbsp;cualquier&nbsp;momento&nbsp;y&nbsp;en&nbsp;cualquier&nbsp;lugar.
          </span>
        </div>
        <div class="text-wrapper_2 flex-col justify-between">
          <span class="text_12">Compañía&nbsp;colaboradora</span>
          <span class="text_13">
            Créditos&nbsp;Formales&nbsp;de&nbsp;México,&nbsp;S.A.P.I.&nbsp;de&nbsp;C.V.,&nbsp;SOFOM,&nbsp;E.N.R.
          </span>
        </div>
        <div class="box_1 flex-col">
          <span class="text_14">Contáctenos</span>
          <div class="image-text_1 flex-row justify-between">
            <img
              class="image_2"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng7fc7ab6f1496c985f3ae729a46450eba96e8c0717bc3520f78429f90a37cff1e.png"
            />
            <span class="text-group_3">support&#64;mercurioestrategica.com</span>
          </div>
          <div class="image-text_2 flex-row justify-between">
            <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng9b220cb481e58d71c7a345883e024f9a55997d950a59bd3c8b53d80ff98a2044.png"
            />
            <span class="text-group_4">
              Fontaneros&nbsp;#&nbsp;83&nbsp;Mz&nbsp;4&nbsp;Lt&nbsp;59&nbsp;U&nbsp;Habitacional&nbsp; El&nbsp;Rosario&nbsp;Tlalnepantla&nbsp;de&nbsp;Baz&nbsp;Mexico&nbsp;54090
            </span>
          </div>
        </div>
      </div>
      <div class="group_3 flex-col"></div>
      <span class="text_15">&nbsp;©&nbsp;Copyright&nbsp;by&nbsp;DIGITAL&nbsp;MONEYBOX</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng638017aecbaca5ccd3cff89ecdfefbf51eea1969b2d513045b4cffe4c7148c45',
          lanhutext0: 'Phone',
          lanhutext1: '1234567891253',
          specialSlot1: { lanhutext0: '1234567891253' },
          slot1: 1
        },
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngc69295cd90d0072a65e09ea7d51def187bcae7ab89d0d0052f4a7d926eccb432',
          lanhutext0: 'Email',
          lanhutext1: '1234567&#64;mail.com',
          specialSlot1: { lanhutext0: '1234567&#64;mail.com' },
          slot1: 1
        },
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd8f1b35bb9d9c2c03af36636b66d881fb1db431483572afd162bb49a041d4a39',
          lanhutext0: 'Location',
          lanhutext1:
            'Fontaneros&nbsp;#&nbsp;83&nbsp;Mz&nbsp;4&nbsp;Lt&nbsp;59&nbsp;U&nbsp;Habitacional&nbsp;El&nbsp;Rosario&nbsp;Tlalnepantla&nbsp;de&nbsp;Baz&nbsp;Mexico&nbsp;54090'
        }
      ],
      constants: {}
    };
  },
  methods: {
    jumpage(url){
      this.$router.push(url)
    }
  }
};
</script>
<style scoped lang="less" src="./assets/index.less" />