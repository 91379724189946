<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  export default {
  data() {
    return {

    }},
    
    methods: {
     
    }
    }
</script>
<style lang="less" src="./assets/common.less">
</style>
